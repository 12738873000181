@import url("https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;800;900&family=Poppins:wght@300;400;500;700&family=Roboto:wght@300;500;700;900&display=swap");

body {
  overflow: auto;
}

body::-webkit-scrollbar {
  display: none;
}
.rfm-marquee {
  background: white !important;
}
.marquee-div {
  position: absolute;
  margin-top: 122px;
  max-width: 100%;
  z-index: 1;
}
@media (max-width: 1277px) {
  .marquee-div {
    margin-top: 107px;
  }
}
@media (max-width: 1205px) {
  .marquee-div {
    margin-top: 127px;
  }
}
@media (max-width: 1195px) {
  .marquee-div {
    margin-top: 123px;
  }
}
@media (max-width: 501px) {
  .marquee-div {
    margin-top: 280px;
  }
}
@media (max-width: 440px) {
  .marquee-div {
    margin-top: 260px;
  }
}
@media (max-width: 395px) {
  .marquee-div {
    margin-top: 230px;
  }
}
@media (max-width: 355px) {
  .marquee-div {
    margin-top: 200px;
  }
}

.chatboat {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
}

.chatboat img {
  width: 80px;
}

.linerm {
  text-decoration: none;
  color: inherit;
}

.line {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  opacity: 1;
  width: 300px;
}

.line-text {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  z-index: 6;
  bottom: 15px;
  left: 80px;
  margin-bottom: 0;
}

.navbar {
  background: transparent
    linear-gradient(
      180deg,
      #1e1e1ee6 0%,
      #1e1e1eb3 22%,
      #1e1e1e80 46%,
      #1e1e1e4d 75%,
      #3f3f3f22 89%,
      #5a5a5a00 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute !important;
  z-index: 1000 !important;
  width: 100%;
  box-shadow: 0px 10px 20px #00000040;
}

.navbar .dropdown {
  cursor: pointer;
}

.baricon {
  color: #ffffff !important;
  font-size: 30px !important;
  display: none;
}

.res-btn {
  display: none;
}

.collegename h2 {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 22px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 1px !important;
  text-align: center;
  font-weight: 700;
}

.collegename p {
  color: #ffffff;
  margin: 0px;
  font-size: 12px;
  text-align: center;
}

.dropdown {
  color: #ffffff;
}

.dropdown-menu.show {
  background: #a5353a !important;
  box-shadow: 0px 3px 6px #0000003b;
  border-radius: 20px;
  opacity: 0.9;
}

.dropdown-menu li {
  text-align: left;
  font-family: "Roboto", "sans-serif";
  font: 15px;
  color: #ffffff;
  opacity: 0.9;
}

.dropdown-menu li:hover {
  color: #a5353a;
}

.open {
  color: #ffdf56;
}

.logo {
  width: 70px;
}

.vert-line {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.nav-btn {
  background: #a5353a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none !important;
  font-weight: 500;
}

.dropTitle {
  cursor: pointer;
}

.nav-btn:hover {
  background: #ffdf56;
  color: #a5353a;
}

.headerbg {
  /* background: #1E1E1EE6 0% 0% no-repeat padding-box; */
  background: #04528c 0% 0% no-repeat padding-box;
  opacity: 0.8;
}

@media screen and (min-width: 1202px) and (max-width: 1405px) {
  .navbar .collegename h2 {
    font-size: small;
  }
  .navbar .collegename p {
    font-weight: 500;
    font-size: xx-small;
  }
}

@media screen and (min-width: 1405px) {
  .navbar .collegename h2 {
    font-size: medium;
  }
  .navbar .collegename p {
    font-weight: 500;
    font-size: xx-small;
  }
}

/* ************************************************
  Event Notice Announcement  components css
************************************************ */
.event {
  height: 500px;
  max-height: 100%;
  overflow-x: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin: 0px 10px;
}

.event::-webkit-scrollbar {
  display: none;
}

.headerdiv1,
.headerdiv2,
.headerdiv3 {
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.headerdiv1 img,
.headerdiv2 img,
.headerdiv3 img {
  width: 50px;
}

.headerdiv1 h2,
.headerdiv2 h2,
.headerdiv3 h2 {
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
  padding: 0px 5px;
  margin: 0;
}

.event-content p {
  margin-bottom: 0px !important;
}

.event-title {
  text-align: left;
  font-size: 15px;
  color: #1e1e1e;
  opacity: 0.9;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
}

.event-date {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #909090;
  opacity: 1;
}

.event-detail,
.notice {
  color: #1e1e1e;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  opacity: 1;
}

.event-btn {
  color: #a5353a;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  opacity: 1;
}

.headerdiv1 {
  background-color: #d9f3e8;
}

.headerdiv2 {
  background-color: #f3eed9;
}

.headerdiv3 {
  background-color: #ffd5d7;
}

.notice-btn {
  background: #a5353a;
  border-radius: 10px;
  padding: 1px 10px;
  margin: 0px 2px;
  color: #ffffff;
}

.headerdiv2 p,
.headerdiv3 p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #6c6c6c;
  opacity: 1;
  font-weight: 400;
}

.view-btn {
  padding: 8px 18px;
  color: #f01010;
  /* border-radius: 20px; */

  /* transition: background-color 0.3s ease; */
}

.view-btn:hover {
  /* background-color: #c3c5c8; */
  border-radius: 20px;
  border: 2px solid red;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .headerdiv3 h2 {
    font-size: large;
  }
  .headerdiv3 p {
    font-size: small;
  }
}

/* ************************************************
Banner components css
************************************************ */
.bannerimg img {
  width: 100%;
  max-height: 100vh;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 5/3;
}

.erp button {
  font-family: "Roboto", sans-serif;
  z-index: 6;
  bottom: 25%;
  margin-right: -36px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transform: matrix(0, -1, 1, 0, 0, 0);
  font: normal normal medium 22px/27px Roboto;
  color: #ffffff !important;
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  border: none !important;
  padding: 13px;
}

.erp1 button {
  font-family: "Roboto", sans-serif;
  z-index: 6;
  bottom: 30%;
  margin-right: -60px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transform: matrix(0, -1, 1, 0, 0, 0);
  font: normal normal medium 22px/27px Roboto;
  color: #ffffff !important;
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  border: none !important;
  padding: 13px;
}

.erp2 button {
  font-family: "Roboto", sans-serif;
  z-index: 6;
  bottom: 30%;
  margin-right: -83px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transform: matrix(0, -1, 1, 0, 0, 0);
  font: normal normal medium 22px/27px Roboto;
  color: #ffffff !important;
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  border: none !important;
  padding: 13px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
}

.carousel-indicators .active {
  background-color: #a5353a !important;
}

/* ************************************************
Award components css
************************************************ */
.titles {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 35px;
  color: #1e1e1e;
  opacity: 1;
  font-weight: 800 !important;
}

/* .award .swiper-slide-next {
    transform: scale(1.2) !important;
    opacity: 1 !important;
} */
.award .swiper-slide img {
  height: 300px;
  max-height: 100%;
  width: 500px;
  border-radius: 20px;
}

.name {
  font-size: 18px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  font-weight: 500;
}

.position {
  font-weight: 700;
  font-size: 18px;
}

.holder p {
  margin-bottom: 0px;
}

.holder {
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.awardyear,
.headerdiv1 p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #6c6c6c;
  opacity: 1;
  font-weight: 400;
}

.impimg {
  width: 20%;
}

.strip {
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: auto !important;
}

/* ************************************************
About components css
************************************************ */
.aboutdiv {
  background: #fff2f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #00000033;
  border-radius: 30px;
  opacity: 0.9;
  padding: 30px;
  z-index: 1;
}

.aboutdiv h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  text-align: center;
  font-weight: 800;
  color: #1e1e1e;
  opacity: 1;
}

.about-text {
  font-family: "Roboto", sans-serif;
  text-align: justify;
  font-weight: 20px;
  color: #1e1e1e;
  opacity: 1;
}

.readmore {
  color: #a5353a;
  border: 1px solid #a5353a;
  border-radius: 23px;
  opacity: 1;
  padding: 3px 30px;
}

.readmore:hover {
  background: #a5353a 0% 0% no-repeat padding-box;
  color: #ffffff;
}

.datadiv {
  background: #fff2f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #00000033;
  border-radius: 9px;
  opacity: 1;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 170px;
  height: 100px;
  background-color: #ffe67b;
  margin: 0px 5px;
}

.datadiv p {
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
}

.count {
  font-size: 30px;
  font-weight: 700;
  color: #1e1e1e;
  opacity: 1;
}

.dataname {
  font-size: 20px;
  color: #525252;
  opacity: 1;
}

.datadiv:hover p {
  color: #a5353a;
}

.playicon {
  color: #a5353a !important;
  font-size: 45px;
}

.img-container {
  position: relative;
  left: -40px;
}

.playdiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #a5353a;
  font-size: 10px;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000008c;
  opacity: 0.8;
}

.playicon {
  margin-right: 5px;
}

.leftarrow {
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ************************************************
 Visitors  components css
************************************************ */
.visitors {
  background: url("../images/others/Group\ 1803.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.visitorimg {
  background: #ffdf56 0% 0% no-repeat padding-box;
  /* background: url('../images/others/visitorimgbg.svg'); */
  opacity: 1;
  border-radius: 40px;
  padding: 10px;
}

.vname {
  font-size: 30px;
}

.vposition {
  font-size: 16px;
  font-weight: 300;
}

.vposition,
.vname {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.note {
  position: relative;
  max-width: 600px;
  max-height: 300px;
}

.noteimg {
  height: 300px;
  object-fit: contain;
  align-items: center;
  display: flex;
  padding: 30px;
}

.left {
  position: absolute;
  top: -40px;
  left: -15px;
}

.right {
  position: absolute;
  right: -15px;
  bottom: -40px;
}

/* ************************************************
Testimonials  components css
************************************************ */
.testimonials {
  background: url("../images/others/testimonialbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.testimonials img {
  background: #ffdf56 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: -100px;
  z-index: 1000;
  width: 200px;
  height: 200px;
}

.testi-detail {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 36px 1px #ffdf5687;
  opacity: 1;
  font-family: "Roboto", sans-serif;
  border-radius: 30px;
  padding: 38px;
  height: 350px;
  max-height: 100%;
}

.testi-name {
  font-size: 24px;
  color: #494949;
  text-align: center;
  margin-top: 60px;
  padding: 10px;
  margin-bottom: 0px;
}

.testi-content {
  font-size: 20px;
  color: #000000;
  font-weight: 300;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.swiper-wrapper {
  padding: 0px 0px 60px 0px;
}

.testimonials .swiper-pagination-bullet {
  background: #cacaca !important;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 4px !important;
  border-radius: 1px !important;
}

.swiper-pagination-bullet-active,
.testimonials .swiper-pagination-bullet-active {
  background-color: #ffdf56 !important;
  width: 30px !important;
}

.row-gap {
  row-gap: 100px !important;
}

/* ************************************************
Important Links  components css
************************************************ */
.linkdiv {
  background: #faeadd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000040;
  border-radius: 30px;
  opacity: 1;
  height: 200px;
}

.uparrow {
  transform: rotate(45deg);
}

/* ************************************************
  RTI components css
************************************************ */
.rtihead td {
  font-family: "Poppins", "sans-serif" !important;
  text-align: left;
  font-size: 18px;
  color: #9a9a9a !important;
  font-weight: 500;
}

/* ************************************************
  Contact components css
************************************************ */
.contact {
  background: url("../images/Contact/Group\ 146.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  padding: 40px;
}

.maindiv {
  width: 100%;
}

.contact h2,
.testimonials h2,
.visitors h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.form {
  padding: 40px;
}

.subdiv {
  background: #fff2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000040;
  border-radius: 20px;
  opacity: 1;
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.subdiv h2 {
  font-size: 20px;
  color: #1e1e1e;
  opacity: 1;
  padding-top: 7px;
}

.contact p {
  margin-bottom: 0px;
  padding: 2px 0px;
  font-size: 15px;
  color: #1e1e1e;
  opacity: 0.9;
  font-weight: 400;
}

.contact p span {
  font-weight: 700;
}

.contact .custom-input {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  background: #fff2f2 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #989898 !important;
  opacity: 0.8 !important;
}

.send {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a5353a;
  border-radius: 23px;
  opacity: 1;
  color: #a5353a;
  padding: 3px 50px;
}

/* ************************************************
  Footer components css
************************************************ */

.footer {
  background: #172f41 0% 0% no-repeat padding-box;
  box-shadow: 0px -5px 6px #0000002e;
}

.footer .collegename {
  height: 45%;
}

.footerhead {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #dddddd;
  opacity: 1;
  width: 80%;
  border-bottom: 2px solid #6c6c6c;
  padding: 2px;
  opacity: 1;
  font-weight: 300;
}

.footer li {
  list-style: none;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  font-size: 16px;
  opacity: 1;
  padding: 2px 0px;
  font-weight: 300;
}

.icon {
  font-size: 25px;
  margin: 5px 10px;
  color: #ffffff !important;
}

.bottombar {
  background: #112738 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bottombar p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #e2e2e2;
  opacity: 1;
  margin-bottom: 0px;
}

.transparent-bg {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  color: #ffffff;
  margin-top: 50px;
}

/* ************************************************
About page Banner components css
************************************************ */
.aboutbg {
  position: relative;
  background: url("../images/others/about-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 374px) {
  .background_insideBanner img {
    object-fit: contain;
    height: 100vh;
  }
}
.about-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.about-banner1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.about-banner h2 {
  font-family: "Lora", serif;
  font-weight: 700;
  font-size: 70px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: uppercase;
}

/* ************************************************
About page about components css
************************************************ */
.abouta-sub {
  flex-direction: column;
}

.abouta-title {
  font-size: 30px;
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #1e1e1e;
  opacity: 1;
  text-align: center;
}

.aboutp {
  font-family: "Poppins", "sans-serif";
  font-size: 18px;
  font-weight: 500;
  color: #1e1e1e;
  opacity: 0.9;
  text-align: justify;
}

.abouta-datadiv {
  margin: 0px !important;
}

/* ************************************************
About page vision mission components css
************************************************ */
.vision {
  margin-top: 100px;
}

.vmtitle {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 40px;
  color: #1e1e1e;
  opacity: 1;
}

.hand {
  width: 35px !important;
  height: 25px !important;
}

.vm-sub p {
  font-family: "Poppins", "sans-serif";
  font-size: 20px;
  color: #a5353a;
  opacity: 1;
  font-weight: 700;
  margin-top: 30px;
}

.vm-sub img {
  position: absolute;
  top: -60px;
  width: 100px;
}

.vm-sub {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  opacity: 0.9;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  text-align: center;
}

/* ************************************************
About page team components css
************************************************ */
.team {
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #fcf2f2 29%, #f6d7d6 60%, #eaa6a3 100%)
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(180deg, #ffffff 0%, #fcf2f2 29%, #f6d7d6 60%, #eaa6a3 100%)
    0% 0% no-repeat padding-box;
  /* opacity: 0.3; */
  background: url("../images/others/Group\ 1651.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.teamcarddiv {
  width: 290px;
  margin: 0px 20px 20px 20px;
}

.teamcard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  height: 270px;
}

.teamname {
  font-family: "Poppins", "sans-serif";
  text-align: left;
  font-size: 17px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}

.postname {
  font-family: "Poppins", "sans-serif";
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

.destiny {
  font-family: "Poppins", "sans-serif";
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 0;
}

.teamcard img {
  border-radius: 20px 20px 0px 0px;
  height: 270px;
}

.card-body {
  background: #a5353a 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 20px 20px;
  opacity: 1;
}

/* ************************************************
About page principal components css
************************************************ */
.princimg {
  border-radius: 0px 30px;
  opacity: 1;
}

.princimg img {
  width: 300px;
}

.princdetail {
  background: #fff2cf 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 0px 30px 0px 31px;
  opacity: 1;
}

.princdetail p {
  padding: 40px;
}

.writer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pen {
  width: 60px;
}

.writer h3 {
  font-family: "Roboto", "sans-serif";
  font-size: 22px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  margin: 0 !important;
  font-weight: 700;
}

.writer div p {
  font-family: "Roboto", "sans-serif";
  font-size: 16px;
  letter-spacing: 0px;
  color: #6b6b6b;
  opacity: 1;
  padding: 0 !important;
  font-weight: 700;
  margin: 0;
}

/* ************************************************
About page Statuary Approval components css
************************************************ */
.approvaldiv {
  background: #fff3f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  opacity: 1;
  width: 400px;
  margin: 10px;
  height: 100%;
}

.approvaldiv h4 {
  font-family: "Poppins", "sans-serif";
  font-size: 20px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
  font-weight: 700;
}

.approvaldiv p {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  font-size: 15px;
  color: #1e1e1e;
  opacity: 0.9;
}

.pdfcard {
  display: flex;
  align-items: center;
  gap: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.pdfcard p {
  font-family: "Roboto", "sans-serif";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
}

/* ************************************************
Staffpage Teachingstaff components css
************************************************ */



.staff-bg {
  background: url("../images//others/img_slide_3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.teachcard img {
  border-radius: 20px 20px 0px 0px;
}

.staffdestiny {
  color: #ffffff;
  opacity: 0.8;
}

.staffname {
  font-family: "Roboto", "sans-serif";
  color: #ffffff;
  opacity: 0.9;
}

/* ************************************************
AboutStaff page intro components css
************************************************ */
/* @media (max-width: 768px) {
  .staffname{
    margin: 2rem !important;
  }
} */
@media (max-width: 1024px) {
  .staffname{
    margin-top: 3rem;
  }
}

.staffintro {
  background: url("/src/assests/images/others/trsnparentbg.svg");
  background: url("/src/assests/images/others/photorealistic-lawyer-environment_23-2151151.jpg");
  background-size: cover;
  background-position: center center;
}

.intro {
  background: transparent
    linear-gradient(
      90deg,
      #a5353a 0%,
      #a5353a 16%,
      #a5353a 39%,
      #a5353ad6 45%,
      #a5353ab5 51%,
      #a5353a00 69%,
      #a5353a00 100%
    )
    0% 0% no-repeat padding-box;
  font-family: "Poppins", "sans-serif";
  color: #ffffff;
  text-align: left;
  padding: 40px 40px 0px 40px;
}

.introimg {
  background: #ffdf56 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #00000040;
  border-radius: 20px;
  opacity: 1;
  height: 100%;
  margin-bottom: -50px;
  max-width: 250px;
  width: 100%;
}

.introimg img {
  border-radius: 20px;
}

.intro .staffname,
.staffpost,
.staffedu,
.staffemail {
  margin-bottom: 3px;
}

.staffname {
  font-size: 25px;
  font-weight: 700;
  opacity: 1;
}

.staffpost {
  font-size: 20px;
  opacity: 0.9;
  font-weight: 500;
}

.staffedu,
.staffemail {
  opacity: 0.9;
  font-size: 18px;
  font-weight: 400;
}

.biobtn {
  border-bottom: 1px solid #a5353a;
  width: 100%;
}

.bio {
  padding: 40px;
}

.biography {
  font-size: 25px;
  opacity: 1;
  color: #8d8d8d;
  font-weight: 900;
  margin: 40px 40px 20px 0px;
  cursor: pointer;
}

.biobtn .active {
  background: #a5353a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 6px 28px;
  opacity: 1;
  color: #ffffff;
}

.biodetail {
  font-family: "Poppins", "sans-serif";
  color: #4b4b4b;
  font-size: 20px;
  text-align: justify;
}

/* ************************************************
Program page components css
************************************************ */
.program-banner {
  background: url("../images/others/programbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.progbtn {
  background: #ffd4d6 0% 0% no-repeat padding-box;
  border-radius: 37px;
  font-family: "Poppins", "sans-serif";
  text-align: center;
  letter-spacing: 0px;
  color: #a5353a;
  opacity: 1;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 500;
}

.progsub {
  font-family: "Poppins", "sans-serif";
  font-size: 18px;
  color: #000000;
  opacity: 1;
  margin-top: 20px;
  text-align: left;
  font-weight: 400;
}

.progsubhead {
  font-weight: 900;
  text-align: left;
  font-size: 30px;
  color: #1e1e1e;
  opacity: 1;
}
.facilities-title {
  color: #a5353a;
  text-decoration: underline;
}

.progsubhead ul {
  padding-left: 0rem !important;
}

.prog-title {
  font-family: "Poppins", "sans-serif";
  text-align: center;
  font-size: 25px;
  color: #1e1e1e;
  opacity: 1;
  font-weight: bold;
}

.progtext,
.database-list li {
  font-family: "Poppins", "sans-serif";
  text-align: left;
  font-size: 17px;
  color: #000000;
  font-weight: 500;
  opacity: 0.9;
  margin-top: 20px;
  text-align: justify;
}

.society .tables {
  max-width: 100%;
}

.tablediv {
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.sem {
  text-align: center;
  font-size: 24px;
  color: #a5353a;
  opacity: 1;
  font-family: "Poppins", "sans-serif";
  font-weight: 700;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Poppins", "sans-serif";
}

.custom-table th {
  padding: 8px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  opacity: 1;
  font-weight: 500;
}

.custom-table td {
  font-size: 15px;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 0.8;
  padding: 10px;
  font-weight: 500;
}

.custom-table tbody tr:nth-child(even) {
  background: #ffeceb;
  border-radius: 10px;
}

.tablehead {
  background: #bd6b68 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px #00000033;
  border-radius: 10px;
  opacity: 1;
}

.link {
  font-size: 15px;
  color: #4285f4;
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  cursor: pointer;
  text-overflow: clip;
}

.prosimg {
  max-width: 290px;
  width: 100%;
}

/* ************************************************
 Infrastructure deatil components css
************************************************ */
.infra-bg {
  background: url("../images/EventsPhoto/1-ALC\ Campus\,\ Kanhe.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.infradetail p {
  font-family: "Poppins", "sans-serif";
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
}

.infrasub2 {
  font-weight: 600 !important;
}

.infrasub,
.infrasub2 {
  font-family: "Poppins", "sans-serif";
  text-align: center;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.9;
  font-weight: 500;
}

.infra {
  margin-top: 60px;
  justify-content: space-between;
  /* align-items: center; */
}

.blockimg {
  width: 100%;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.blockimg img {
  border-radius: 20px;
  /* height: 300px; */
  max-height: 100%;
  /* width: 550px; */
}

/* transition: transform 0.3s ease; */

.blockimg img:hover {
  transform: scale(1.1);
}

.sai .swiperimg {
  height: 270px;
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
}

/* ************************************************
  Alumni pagecss
************************************************ */
.Alumni-bg {
  background: url("../images/alumni/Farewell.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.alimg {
  padding: 10px;
}

.job {
  font-family: "Poppins", "sans-serif";
  font-size: 18px;
  font-weight: 500;
  color: #1e1e1e;
  opacity: 1;
}

.formdiv,
.dformdiv {
  background: #a5353a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.formdiv img,
.dformdiv img {
  border-radius: 20px;
}

.formdiv:hover {
  background: #f6c904;
}

.formdiv img:hover {
  zoom: 1.1;
}

.achievement {
  background: url("../images/others/Group\ 1804.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.achievementimg img {
  height: 400px;
  max-height: 100%;
  width: 500px;
  border-radius: 20px;
}

/* ************************************************
  Grevieance  page css
************************************************ */
.grev-div {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.grev-sub {
  background: #f7e6bc 0% 0% no-repeat padding-box;
  padding: 30px 40px;
}

.grev-form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000033;
  border-radius: 20px;
  opacity: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grev-form .custom-input {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #989898 !important;
  opacity: 0.8 !important;
}

.grev-btn {
  background: #a5353a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 40px;
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Poppins", "sans-serif";
  border: none;
}

.comidiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

/* ************************************************
 StudentLife page css
************************************************ */
.flarge {
  display: flex;
  justify-content: center;
  margin: 0px 20px;
}

.flarge img {
  width: 800px;
  padding: 0px 10px;
}

.fsmalldiv {
  display: flex;
  justify-content: center;
}

.fsmall img {
  height: 150px;
}

.fdlarge {
  display: flex;
}

.fdlarge img {
  width: 700px;
  padding: 0px 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

/* ************************************************
  Library page Design css
************************************************ */

.l-img img {
  border-radius: 20px;
  width: 100%;
  height: 270px;
  max-height: 100%;
}

.lib {
  max-width: 100%;
}

.dl-img img {
  height: 450px;
  width: 100%;
  max-height: 100%;
  border-radius: 20px;
}

.access-btn {
  border: 1px solid #4285f4;
  border-radius: 20px;
  opacity: 1;
  padding: 8px 20px;
}

.dropheader {
  border-radius: 20px 20px 0px 0px;
}

.Ldropdown {
  background: #a5353a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003d;
  opacity: 1;
  text-align: left;
  font-size: 24px;
  color: #ffffff !important;
  padding: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownMenu,
.mobiledropdownMenu {
  background: #fff3f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.icon {
  cursor: pointer;
}

.dropdownMenu li,
.EdropdownMenu li {
  text-align: left;
  font-size: 22px;
  color: #4a4a4a;
  opacity: 1;
  list-style: none;
  padding: 15px;
  border-bottom: 1px solid #c8c8c8;
  cursor: pointer;
}

.mobiledropdown {
  z-index: 1;
}

.mobiledropdownMenu li {
  text-align: left;
  font-size: 16px;
  color: #4a4a4a;
  opacity: 1;
  list-style: none;
  padding: 5px 10px;
  border-bottom: 1px solid #c8c8c8;
  cursor: pointer;
}

.dropdownMenu li:last-child {
  border-bottom: none;
}

.dropdownMenu li:hover {
  background-color: #a5353a;
  color: #ffffff;
}

.Lcustom-table td {
  font-family: "Poppins", "sans-serif" !important;
  text-align: left;
  font-size: 18px !important;
  color: #1e1e1e;
  opacity: 1;
  font-weight: 500 !important;
  padding: 10px;
}

.Lcustom-table tbody tr:nth-child(odd) {
  background: #ebebeb;
  border-radius: 10px;
}

.custom-table tbody tr:nth-child(odd) {
  background: #ffffff;
  border-radius: 10px;
}

.lthead td {
  font-weight: 700 !important;
}

.tableName {
  font-family: "Poppins", "sans-serif";
  font-size: 24px;
  color: #a5353a;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
}

.database-list li {
  margin-top: 0px !important;
}

.database-list li::marker {
  color: #a5353a !important;
  font-size: 20px;
}

.scanbg {
  background: url("../images/library/Extension\ Reading\ Room\ 2.png");
  background-repeat: no-repeat;
  /* background-position: center center; */
}

.scanner {
  background: #a5353a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.scan {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scan p {
  font-family: "Poppins", "Sans-serif";
  text-align: center;
  font-weight: 400;
  /* font-size: 20px; */
  color: #1e1e1e;
}

/* ************************************************
 Admission Design css
************************************************ */

.adm-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a5353a;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  font-family: "Poppins", "sans-serif";
  color: #8d8d8d;
  padding: 10px;
  width: 250px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
}

.admbtn-div {
  justify-content: space-between;
}

.btns-div {
  border-bottom: 1px solid #a5353a;
}

.activeTab::after {
  content: "";
  width: 26px;
  height: 20px;
  background: #a5353a;
  position: absolute;
  bottom: -7px;
  right: 20px;
  transform: rotate(45deg);
  border-top: 1px solid #a5353a;
  border-left: 1px solid #a5353a;
}

.activeTab {
  background-color: #a5353a;
  color: #ffffff;
}

.archive {
  background: #faeadd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  color: #1e1e1e;
  text-transform: uppercase;
  opacity: 0.9;
  font-weight: 900;
  align-items: center;
}

.arc-sidetext {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formdiv .overlay {
  opacity: 0 !important;
}

.formdiv:hover .overlay {
  opacity: 1 !important;
}

.overlay-text {
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 10px;
}

.dformdiv {
  position: relative;
}

.dformdiv:hover .overlay {
  opacity: 1;
  border-radius: 20px;
}

.faq-text {
  font-family: "Poppins", "sans-serif";
  text-align: left;
  font-size: 20px;
  color: #5d5d5d;
  opacity: 1;
  font-weight: 500;
}

.faqdiv {
  height: 80vh;
  overflow: auto;
}

.faqdiv::-webkit-scrollbar {
  display: none;
}

.faq-ans {
  font-family: "Poppins", "sans-serif";
  text-align: left;
  font-size: 15px;
  color: #4a4a4a;
  opacity: 0.9;
  font-weight: 500;
}

.ansactive {
  background: #ffeceb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.qassurance {
  background: #e9f6fe 0% 0% no-repeat padding-box;
}

/* ************************************************
 Events page css
************************************************ */
.Edropdown {
  background: #fff3f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EdropdownMenu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.EdropdownMenu li {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  text-align: left;
  font-family: 22px;
  color: #4a4a4a;
  opacity: 1;
}

.EdropdownMenu li:last-child {
  border-bottom: none;
}

.eventimg img {
  border-radius: 10px;
  height: 200px;
  max-height: 100%;
  width: 100%;
}

.Eventmobiledrop {
  z-index: 1;
  width: 200px;
  background: #fff3f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  padding: 0;
}

.Eventmobiledrop .Edropdown {
  box-shadow: none;
  font-size: 16px;
  padding: 10px;
  color: #1e1e1e;
  font-weight: 600;
  font-family: "Poppins", "sans-serif";
}

.eventimg p {
  font-family: "Poppins", "sans-serif";
  font-weight: 600;
  text-align: left;
  font-size: 18px;
  color: #1e1e1e;
  opacity: 1;
  margin: 10px 0px;
}

.edetail h2 {
  font-family: "Poppins", "sans-serif";
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  color: #1e1e1e;
  opacity: 1;
}

.eventimg p:hover {
  color: #a5353a;
}

.eventimg {
  position: relative;
}

.e-img {
  position: relative;
}

.eover-text {
  border: 1px solid #ffffff;
  border-radius: 20px;
  opacity: 1;
  font-size: 12px !important;
  padding: 3px 10px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 300;
}

.e-img:hover .overlay {
  opacity: 1;
}

.edetail img {
  border-radius: 20px;
}

.leftarr {
  border: 1px solid #3d3d3d;
  border-radius: 50%;
  height: 30px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}

.eventbar {
  display: none;
}

/* ************************************************
 societies page Design css
************************************************ */
.title-btn {
  background: #a5353a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 30px;
  opacity: 1;
  width: 370px;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
}

.title-border {
  border: 2px solid #ffdf56;
  border-radius: 0px 30px;
  opacity: 1;
  padding: 8px;
}

.collegec {
  background: #fff1e2 0% 0% no-repeat padding-box;
}

.cmtblock {
  /* background: #F1A95E 0% 0% no-repeat padding-box; */
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  margin: 15px 0px;
  align-items: center;
}

.cmtblock p {
  font-family: "Poppins", "sans-serif";
  font-size: 20px;
  font-weight: 500;
  color: #3d3d3d;
  opacity: 1;
  margin-right: 15px;
}
.arrow-text {
  text-decoration: none !important;
}
.arowdiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 8px 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmtblockdiv {
  padding: 100px 0px 30px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media (max-width: 570px) {
  .cmtblockdiv {
    grid-template-columns: repeat(1, 1fr);
  }
}

.cmtblockdiv {
  /* background: url('../images/others/poleimg.png'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}

/* .polehead{
    background: transparent linear-gradient(90deg, #FEE1BD 0%, #C39A7F 72%, #C79868 93%, #AF855D 100%) 0% 0% no-repeat padding-box;
opacity: 1;
width: 100px;
height: 20px;
}
.pole {
    background: transparent linear-gradient(89deg, #FEE1BD 0%, #C39A7F 72%, #C79868 93%, #AF855D 100%) 0% 0% no-repeat padding-box;
    width: 50px;
    height: 800px; 
}
.polediv{
    padding:0px 200px;
} */
/* ************************************************
 Cu;tural page Design css
************************************************ */
.noticepage {
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.notice-cont {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 10px;
}

.notice-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  width: 320px;
  max-width: 100%;
}

.notice-btn p {
  font-family: "Poppins", "sans-serif";
  font-weight: 700;
  color: #8d8d8d;
  font-size: 25px;
}

.nactiveTab {
  background: #e8c73a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  border-radius: 10px;
}

.nactiveTab p,
.aactiveTab p {
  color: #1e1e1e;
}

.ntdate {
  font-family: "Poppins", "sans-serif";
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #d1aa04;
  opacity: 1;
  margin-top: 10px;
}

.ntname {
  font-weight: 600;
}

.aactiveTab {
  background: #ed4646 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  border-radius: 10px;
}

/* ************************************************
 Cultural page Design css
************************************************ */
.Cdropdown {
  background: #f1a95e 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #0000003d;
  opacity: 1;
  color: #ffffff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.objectlist li {
  list-style-type: none;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
}

.image img {
  border-radius: 20px;
  height: 340px;
  width: 100%;
}

.cspan-7 {
  grid-column: span 7;
}

.cspan-5 {
  grid-column: span 5;
}

.cspan-6 {
  grid-column: span 6;
}

.contactpage {
  padding-top: 123px;
}

/* ************************************************
 Responsive Design css
************************************************ */

@media (max-width: 426px) {
  .aboutdiv {
    padding: 15px !important;
  }

  .alimg {
    padding: 3px !important;
  }

  .alimg img {
    border-radius: 3px !important;
  }

  .staffname {
    font-size: 19px;
  }

  .staffpost,
  .staffedu,
  .staffemail {
    font-size: 16px;
  }

  .datadiv,
  .abouta-sub .datadiv {
    height: 60px;
    width: 100px;
    border-radius: 9px;
  }

  .count,
  .custom-table td {
    font-size: small;
  }

  .contact {
    padding: 15px;
  }

  .dataname {
    font-size: small;
  }

  .custom-table td {
    padding: 2px;
  }

  .biodetail {
    font-size: 14px !important;
  }

  .noteimg {
    height: 120px;
  }

  .rtihead td {
    font-size: 12px;
  }

  .custom-table td {
    font-size: 12px;
  }

  .grev-btn {
    font-size: 13px !important;
  }

  .vm-sub img {
    width: 60%;
    margin-top: 25px !important;
  }

  .tableName {
    font-size: 20px;
  }

  .notice-btn {
    border-radius: 5px;
    margin: 5px;
  }

  .notice-btn p {
    font-size: 12px !important;
  }
}

@media (max-width: 440px) {
  .footer .collegename {
    flex-direction: column;
    align-items: center;
  }

  .active {
    padding: 6px;
  }

  .bottombar {
    flex-direction: column;
    text-align: center;
  }

  .logo {
    width: 50px;
  }

  .left,
  .right {
    width: 100px !important;
  }

  .testi-detail {
    padding: 15px;
  }

  .testi-content {
    font-size: 16px;
  }

  .testi-name {
    margin-top: 85px;
  }

  .bio {
    padding: 10px;
  }

  .biography {
    margin: 2px !important;
    font-size: 15px !important;
  }

  .l-img img {
    height: 180px;
  }

  .admdiv {
    padding: 0px 17px;
  }

  .contactpage {
    padding-top: 97px !important;
  }

  .fsmall img {
    height: 90px;
  }
}

@media (max-width: 507px) {
  .progbtn {
    font-size: 15px;
  }

  .datestrip p,
  .infradetail p {
    font-size: 16px;
  }
  .erp button {
    margin-right: -30px;
    font-size: small;
    border-radius: 10px 10px 0px 0px;
    bottom: 25%;
    padding: 10px;
  }
  .datadiv,
  .abouta-sub .datadiv {
    height: 60px;
    width: 100px;
    border-radius: 9px;
  }

  .about-banner h2 {
    font-size: 65px;
  }

  .abouta-sub .count {
    font-size: small;
  }

  .abouta-sub .contact {
    padding: 15px;
  }

  .abouta-sub .dataname {
    font-size: small;
  }

  .aboutp {
    font-size: 15px !important;
  }

  .writer p,
  .progsub1 {
    font-size: 13px !important;
  }

  .writer {
    padding: 2px;
  }

  .writer h3,
  .abouta-title,
  .prog-title {
    font-size: 18px !important;
  }

  .vm-text,
  .progsub,
  .sem {
    font-size: 15px !important;
  }

  .princdetail p {
    padding: 15px !important;
  }

  .teamname {
    font-size: 18px !important;
  }

  .destiny {
    font-size: 15px !important;
  }

  .custom-table th {
    font-size: small;
  }

  .line {
    width: 150px;
  }

  .line-text {
    font-size: small;
    left: 50px;
    bottom: 10px;
  }

  .blockdiv {
    flex-direction: column;
  }

  .biodetail {
    font-size: 16px;
  }

  .biography {
    margin: 20px;
  }

  .prosimg {
    /* max-width: 100px !important; */
    width: 100%;
  }

  .grev-form {
    padding: 15px 20px;
    width: 100%;
  }

  .grev-btn {
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 17px;
  }

  .vm-sub img {
    width: 50%;
  }

  .image img {
    border-radius: 10px;
    height: 160px !important;
  }
}

@media (max-width: 580px) {
  .form {
    width: 100%;
  }
}

@media (max-width: 695px) {
  .intro {
    justify-content: center;
    text-align: center;
    padding: 10px;
  }

  .introimg {
    margin-bottom: 0px;
  }

  .bio {
    padding: 0px 20px;
  }

  .biography {
    font-size: 18px;
  }

  .about-banner h2 {
    font-size: 30px !important;
  }

  .notice-btn p {
    font-size: 18px;
  }
}

@media (max-width: 769px) {
  .form {
    padding: 15px;
  }

  .logo-1,
  .collegename {
    display: none;
  }

  .vname {
    font-size: medium;
  }

  .vposition {
    font-size: small;
  }

  .vm-sub p {
    font-size: 16px !important;
  }

  .teamcarddiv {
    width: 290px;
    margin: 37px;
  }

  .tables {
    padding: 0;
    width: 100%;
    overflow: auto;
  }

  .about-banner h2 {
    font-size: 40px;
  }

  .alimg {
    padding: 5px;
  }

  .alimg img {
    border-radius: 8px !important;
  }

  .prosimg {
    max-width: 200px;
    width: 100%;
  }

  .archive {
    font-size: 16px;
  }

  .arc-sidetext {
    font-size: 12px !important;
  }

  .adm-btn {
    font-size: 15px;
    width: 217px;
  }

  .admdiv div {
    justify-content: center !important;
  }

  .notice-btn p,
  .cmtblock p {
    font-size: 17px;
  }

  .cmtblock {
    padding: 10px 20px;
  }

  .image img {
    border-radius: 15px;
    height: 250px;
  }

  .achievementimg img {
    height: 250px;
  }
}

@media (max-width: 990px) {
  .abouta-sub {
    flex-direction: row !important;
  }

  .img-container {
    left: 0;
  }

  .sub-visitor {
    flex-direction: column;
  }

  .footer .collegename img {
    width: 80px;
    height: 80px;
  }

  .vm-sub p {
    font-size: 17px;
  }

  .principaldiv {
    justify-content: center;
  }

  .princdetail {
    margin-top: 20px;
  }

  .vimg {
    margin-bottom: 30px;
  }

  .aimgdiv {
    position: relative;
    right: 0;
  }

  .award .swiper-slide-next {
    transform: none !important;
  }

  .eventbar {
    display: block;
    font-size: 30px;
  }

  .EdropdownMenu,
  .dropdownMenu {
    display: none;
  }
}

@media (max-width: 1200px) {
  .cmtblockdiv {
    background-size: contain;
  }
}

@media (max-width: 1200px) {
  .dropdown {
    display: none;
  }

  .baricon,
  .res-btn {
    display: block;
  }

  .dropTitle {
    text-align: left;
  }

  .navbar {
    align-items: start !important;
    padding: 15px !important;
  }

  .admbtn-div {
    justify-content: space-evenly;
  }

  .cmtblockdiv {
    background: none;
  }
}
